import React from 'react'
import { Helmet } from 'react-helmet'
import styles from './iscriviti.module.css'
import Layout from '../components/layout'
import FormSignin from '../components/form-signin'
import { Grid, Typography } from '@material-ui/core'

class Iscriviti extends React.Component {
  render() {
    const pageTitle = 'Movimento Rivoluzione Fiscale - Iscriviti'

    return (
      <Layout location={this.props.location}>
        <Helmet title={pageTitle}>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <div className={styles.container}>
          <div className={styles.gridContainer}>
            <Grid container spacing={2} className={styles.documentsContainer}>
              <Grid item xs={12} sm={3} className={styles.leftTextContainer}>
                <Typography variant={'h1'} className={styles.title}>
                  Iscriviti al movimento rivoluzione fiscale
                </Typography>
              </Grid>
              <FormSignin />
            </Grid>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Iscriviti

