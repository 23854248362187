import React, { useEffect, useState } from 'react'
import styles from './index.module.css'

import {
  Grid,
  Typography,
  Button,
  Checkbox,
  makeStyles,
  CircularProgress
} from '@material-ui/core'
import cn from 'classname'
import Input from '../../input'

export default ({ step, register, errors, isValid, isLoading}) => {
  const [privacyCheckbox, setPrivacyCheckbox] = useState(false)
  const useStyles = makeStyles(() => ({
    rootCheckbox: {
      '&$checked': {
        color: '#1D9517',
      },
    },
    checked: {}
  }))

  useEffect(() => {
    if (step === 1) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [step])

  const classes = useStyles()
  return (
    <Grid container spacing={2} className={(step !== 1) && styles.doNotDisplay }>
      <Grid item xs={12}>
        <div className={styles.headerContainer}>
          <Typography variant={'body1'} className={styles.firstTypography}>
            1. Dati anagrafici
          </Typography>
          <Typography variant={'body1'} className={step === 1 ? styles.selectedTypography : null}>
            2.Recapiti
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Input
          className={styles.input}
          name="phone"
          inputRef={register}
          placeholder="Inserisci il tuo telefono"
          fullWidth
          label="Telefono"
          helperText={errors?.phone?.message}
          error={!!errors?.phone?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          className={styles.input}
          name="email"
          inputRef={register}
          placeholder="Inserisci la tua email"
          fullWidth
          label="Email"
          helperText={errors?.email?.message}
          error={!!errors?.email?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          className={styles.input}
          name="password"
          isPassword
          inputRef={register}
          placeholder="Inserisci la tua password"
          fullWidth
          label="Password"
          helperText={errors?.password?.message}
          error={!!errors?.password?.message}
        />
      </Grid>
      <Grid item xs={1}>
        <Checkbox
          classes={{
            root: classes.rootCheckbox,
            checked: classes.checked
          }}
          checked={privacyCheckbox}
          onChange={() => setPrivacyCheckbox(prevState => !prevState)}
        />
      </Grid>
      <Grid item xs={11} >
        <Typography variant={'body1'} className={styles.privacyTypography}> Acconsento, ai sensi del Regolamento UE 2016/679, liberamente e in modo esplicito, al trattamento dei dati personali qui inseriti, incluse informazioni che riguardano le mie opinioni politiche e sociali. I dati non saranno mai utilizzati per finalità commerciali, né ceduti a terzi. Riceverai la Newsletter MRF, potrai cancellarti quando lo vorrai. <a href="http://assets.ctfassets.net/mliqjt4giyw6/6mhdAmLg2mwFQqCY2CVqfi/33eab62be4cacdc61e9c10aeb0f57364/informativa_privacy_MOVIMENTO_RIVOLUZIONE_FISCALE.pdf" target="_blank">Informativa privacy estesa</a>.</Typography>
      </Grid>
      <Grid item xs={12} >
        <Button
          classes={{
            root: cn(styles.buttonGreen, (!(isValid) || !privacyCheckbox) && styles.buttonDisabled),
          }}
          disabled={!(isValid) || !privacyCheckbox || isLoading}
          variant="contained"
          type={'submit'}
        >
          {isLoading ? <CircularProgress size={20} /> : 'Conferma dati'}
        </Button>
      </Grid>
    </Grid>
  )
}
