import React from 'react'
import {
  Snackbar,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

export default ({
  open,
  handleClose,
  severity,
  message,
  duration = 6000,
  ...props
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};
