import React from 'react'
import styles from './index.module.css'

import {
  Grid,
  Typography,
  Button, makeStyles,
} from '@material-ui/core'
import cn from 'classname'
import Input from '../../input'
import CustomSelect from '../../custom-select'
import { Controller } from 'react-hook-form'
import provinceList from '../../../../assets/json/province.json'
import _ from 'lodash'
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

export default ({ setStep, step, register, control, errors, isValid, onDocumentChange}) => {
  const listaSessi = [
    {
      value: 'M',
      label: 'Uomo'
    },
    {
      value: 'F',
      label: 'Donna'
    },
    {
      value: 'O',
      label: 'Non specificato'
    }
  ]
  const listaProvince = provinceList.map(provincia => ({
    value: provincia.sigla,
    label: provincia.sigla
  })).sort((a,b) => (a.label > b.label) ? 1 : (a.label < b.label) ? -1 : 0)

  const listaRegioni = _.uniq(provinceList.map(provincia => provincia.regione)).map(regione => ({
    value: regione,
    label: regione
  })).sort((a,b) => (a.label > b.label) ? 1 : (a.label < b.label) ? -1 : 0)

  const useStyles = makeStyles(() => ({
    datePickerRoot: {
      height: 50,
      boxShadow: '0px 3px 6px #00000029',
      fontSize: "1rem",
      fontWeight: 700,
      padding: '0 20px',
      display: 'flex',
      justifyContent: 'center'
    },
    label: {
      fontSize: "1rem",
      fontFamily: 'Avenir Black',
      fontWeight: 'bold',
      marginBottom: 2,
    },
  }))

  const classes = useStyles()

  return (
    <Grid container spacing={2} className={(step !== 0) ? styles.doNotDisplay : null}>
      <Grid item xs={12}>
        <div className={styles.headerContainer}>
          <Typography variant={'body1'} className={cn(styles.firstTypography, {
            [styles.selectedTypography]: step === 0
          })}>
            1. Dati anagrafici
          </Typography>
          <Typography variant={'body1'} >
            2.Recapiti
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Input
          className={styles.input}
          name="name"
          inputRef={register}
          placeholder="Inserisci il tuo nome"
          fullWidth
          label="Nome"
          helperText={errors?.name?.message}
          error={!!errors?.name?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          className={styles.input}
          name="surname"
          inputRef={register}
          placeholder="Inserisci il tuo cognome"
          fullWidth
          label="Cognome"
          helperText={errors?.surname?.message}
          error={!!errors?.surname?.message}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography
          className={classes.label}
        >
          {'Data di Nascita'}
        </Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <Controller
          as={
              <KeyboardDatePicker
                format="DD/MM/YYYY"
                emptyLabel={'__/__/____'}
                mask={'__/__/____'}
                variant={'outlined'}
                classes={{
                  root: classes.datePickerRoot,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
          }
          name="birthDate"
          control={control}
          register={register}
        />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <Controller
          as={
            <CustomSelect
              themed
              inputRef={register}
              className={styles.input}
              label="Sesso"
              placeholder="Seleziona il sesso"
              items={listaSessi}
            />
          }
          name="gender"
          control={control}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          className={styles.input}
          name="birthCity"
          inputRef={register}
          placeholder="Inserisci la tua città di nascita"
          fullWidth
          label="Città di nascita"
          helperText={errors?.birthCity?.message}
          error={!!errors?.birthCity?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={
            <CustomSelect
              themed
              inputRef={register}
              className={styles.input}
              label="Regione di nascita"
              placeholder="Inserisci la tua regione di nascita"
              items={listaRegioni}
            />
          }
          name="birthRegion"
          control={control}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={
            <CustomSelect
              themed
              inputRef={register}
              className={styles.input}
              label="Provincia di nascita"
              placeholder="Inserisci la tua provincia di nascita"
              items={listaProvince}
            />
          }
          name="birthProvince"
          control={control}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={8}>
        <Input
          className={styles.input}
          name="residenceMunicipality"
          inputRef={register}
          placeholder="Inserisci la tua città"
          fullWidth
          label="Comune di residenza"
          helperText={errors?.residenceMunicipality?.message}
          error={!!errors?.residenceMunicipality?.message}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          className={styles.input}
          name="CAP"
          inputRef={register}
          placeholder="Inserisci il CAP"
          fullWidth
          label="CAP"
          helperText={errors?.CAP?.message}
          error={!!errors?.residenceMunicipality?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          className={styles.input}
          name="profession"
          inputRef={register}
          placeholder="Inserisci la tua professione"
          fullWidth
          label="Professione"
          helperText={errors?.profession?.message}
          error={!!errors?.profession?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          className={styles.input}
          name="document"
          placeholder="Seleziona un documento d'identità, la patente, o il passaporto"
          fullWidth
          label="Documento di riconoscimento (carta d'identità, patente, o passaporto)"
          type="file"
          onChange={onDocumentChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          classes={{
            root: cn(styles.buttonGreen, !isValid && styles.buttonDisabled),
          }}
          disabled={!(isValid)}
          variant="contained"
          onClick={() => setStep(step + 1)}
        >
          Conferma dati
        </Button>
      </Grid>
    </Grid>
  )
}
