import React, { useMemo, useState, useEffect } from 'react'
import {
  Typography,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
} from '@material-ui/core'
import cn from 'classname'

const useStyles = makeStyles((theme) =>
  ({
    heightTot: {
    },
    formControl: {
      height: 50,
      width: "calc(100% - 2px)",
      overflow: "hidden",
      boxShadow: '0px 3px 6px #00000029',
      padding: 0,
      justifyContent: 'center',
      '&>div:first-child': {
        height: '100% !important'
      }
    },
    themedFormControl: {
    },
    formControlFocused: {
      border: `1px solid ${theme.palette.common.white}`,
      backgroundColor: theme.palette.common.white,
    },
    formControlFocusedMini: {
      backgroundColor: theme.palette.common.white,
    },
    select: {
      backgroundColor: "transparent",
      padding: '0 20px',
      width: "100%",
      color: theme.palette.common.white,
    },
    themedSelect: {
      color: theme.palette.text.primary,
    },
    selectFocused: {
      color: theme.palette.common.black,
    },
    selectMenu: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      paddingRight: "66px !important",
      height: '100% !important',
      display: 'flex',
      alignItems: 'center'
    },
    selectMenuMini: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      paddingRight: "30px !important",
    },
    themedIcon: {
      color: theme.palette.text.primary,
    },
    iconSelected: {
      color: theme.palette.common.black,
    },
    adornmentIcon: {
      fontSize: 18,
    },
    label: {
      fontSize: "1rem",
      fontFamily: 'Avenir Black',
      fontWeight: 'bold',
      marginBottom: 2,
    },
    themedLabel: {
      color: theme.palette.text.primary,
    },
    subLabel: {
      fontWeight: 100,
      fontSize: 15,
      lineHeight: "unset",
      marginBottom: 8,
    },
    paper: {
      boxShadow: '0px 3px 6px #00000029',
      border: "1px solid #234345",
      textOverflow: "ellipsis",
      transform: "translateY(4px) !important",
    },
    menuItemRoot: {
      fontSize: 18,
      fontWeight: 400,
      maxWidth: "100%",
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.info.main,
      },
      "&:not(:last-child)": {
        borderBottom: "1px solid #234345",
      },
    },
    menuItemHidden: {
      fontSize: 21,
      maxWidth: "100%",
      borderBottom: "1px solid #234345",
      opacity: "1 !important",
      paddingBottom: 15,
      backgroundColor: "transparent !important",
    },
  })
);

export default ({
  className,
  label,
  subLabel,
  placeholder,
  items,
  value,
  themed,
  mini,
  renderIcons,
  ...props
}) => {

  const classes = useStyles()
  const [isFocused, setIsFocused] = useState(false);
  const derivedClasses = useMemo(
    () => ({
      selectClasses: {
        select: cn(
          classes.select,
          themed ? classes.themedSelect : undefined,
          isFocused ? classes.selectFocused : ""
        ),
        selectMenu: mini ? classes.selectMenuMini : classes.selectMenu,
        icon: cn(
          mini ? classes.iconMini : classes.icon,
          themed ? classes.themedIcon : undefined,
          isFocused ? classes.iconSelected : ""
        ),
      },
      paperClasses: {
        paper: classes.paper,
      },
      menuItemHiddenClasses: {
        root: classes.menuItemHidden,
      },
      menuItemClasses: {
        root: classes.menuItemRoot,
      },
    }),
    [classes, isFocused, themed, mini]
  );

  const switchIsFocused = useMemo(
    () => ({
      1: () => setIsFocused(true),
      0: () => setIsFocused(false),
    }),
    [setIsFocused]
  );

  useEffect(() => {
    setIsFocused(false);
  }, [value]);

  const getMenuProps = useMemo(
    () => ({
      classes: derivedClasses.paperClasses,
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    }),
    [derivedClasses]
  );

  return (
    <div className={className}>
      {label && (
        <Typography
          variant="body1"
          className={cn(
            classes.label,
            themed ? classes.themedLabel : undefined
          )}
        >
          {label}
        </Typography>
      )}
      {subLabel && (
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.subLabel}
        >
          {subLabel}
        </Typography>
      )}
      <FormControl
        className={cn(
          !mini ? classes.formControl : undefined,
          themed ? classes.themedFormControl : undefined,
          isFocused
            ? mini
            ? classes.formControlFocusedMini
            : classes.formControlFocused
            : undefined
        )}
      >
        <Select
          value={value}
          displayEmpty
          disableUnderline
          classes={derivedClasses.selectClasses}
          onOpen={switchIsFocused[1]}
          onBlur={switchIsFocused[0]}
          MenuProps={getMenuProps}
          inputProps={{
            classes:{
              root: classes.heightTot,
            }
          }}
          {...props}
        >
          {placeholder && (
            <MenuItem
              classes={derivedClasses.menuItemHiddenClasses}
              value=""
              disabled
            >
              {placeholder}
            </MenuItem>
          )}
          {items.map((item) => (
            <MenuItem
              onBlur={switchIsFocused[0]}
              key={item.value}
              classes={derivedClasses.menuItemClasses}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
