import React, { useState, useCallback, useMemo } from 'react'
import {
  makeStyles,
  Grid,
  Paper,
} from '@material-ui/core'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Step1 from './step-1'
import Step2 from './step-2'
import Step3 from './step-3'
import provinceList from '../../../assets/json/province.json'
import _ from 'lodash'
import { useMutation } from 'react-query'
import CustomSnackbar from '../custom-snackbar'

const schemaStep1 = yup.object().shape({
  name: yup
    .string()
    .min(2, 'Minimo 2 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required('Nome richiesto'),
  surname: yup
    .string()
    .min(2, 'Minimo 2 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required("Cognome richiesto"),
  birthDate: yup
    .date()
    .required("Data di nascita richiesto"),
  gender: yup
    .string()
    .oneOf(['M', 'F', 'O'])
    .required(),
  birthCity: yup
    .string()
    .min(2, 'Minimo 2 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required("Citta di nascita richiesto"),
  birthRegion: yup
    .string()
    .oneOf(_.uniq(provinceList.map(provincia => provincia.regione)))
    .required("Regione di nascita richiesto"),
  birthProvince: yup
    .string()
    .oneOf(_.uniq(provinceList.map(provincia => provincia.sigla)))
    .required("Provincia di nascita richiesto"),
  residenceMunicipality: yup
    .string()
    .min(2, 'Minimo 2 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required("Comune di residenza richiesto"),
  CAP: yup
    .string()
    .length(5, "Il Cap deve essere di 5 caratteri")
    .matches(/^[0-9]*$/, "Il Cap deve essere composto da soli numeri")
    .required("CAP richiesto"),
  profession: yup
    .string()
    .min(2, 'Minimo 2 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required("Professione richiesto"),
}).required();

const schemaStep2 = yup.object().shape({
  phone: yup
    .string()
    .min(10, 'Minimo 10 caratteri')
    .max(20, 'Massimo 20 caratteri')
    .required('Nome richiesto'),
  email: yup
    .string()
    .email("Inserire Email valida")
    .required("Email richiesto"),
  password: yup
    .string()
    .min(6, 'Minimo 6 caratteri')
    .max(50, 'Massimo 50 caratteri')
    .required("Password richiesto"),
}).required();

const schema = schemaStep1.concat(schemaStep2);

export default ({  }) => {
  const [step, setStep] = useState(0)

  const useStyles = makeStyles(() => ({
    rootPaper: {
      padding: '1rem 3rem 2rem',
      borderRadius: '0'
    }
  }))

  const classes = useStyles()

  const { register, handleSubmit, formState, errors, watch, control } = useForm({
    defaultValues: {
      birthDate: null
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const formFields = watch()

  const restURI = process.env.REACT_APP_API_BASE_URL

  const [documentInfo, setDocumentInfo] = useState(null);
  const [documentData, setDocumentData] = useState(null);

  // SNACK BAR ERROR
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenErrorSnackbar(false);
  };


  const { isLoading, mutate } = useMutation('postUsers', ({ data }, ) =>
    fetch(`${restURI}/users`, {
      method: 'post',
      body: JSON.stringify(data)
    })
      .then(async (res) => {
        if (res.status !== 200) {
          const resp = await res.json();
          throw new Error(resp.message)
        }
        return res;
      })
      .then(res => res.json()),
    {
      onSuccess: () => setStep(step + 1),
      onError: (e) => {
        setMessage(e.message);
        setOpenErrorSnackbar(true);
      }
    }
  );

  const onSubmit = useCallback(
    (_data) => {
      mutate({
        data: {
          ..._data,
          documentData,
          documentInfo,
        },
      });
    },
    [step, setStep, mutate, documentData, documentInfo]
  );

  const isValidFirstStep = useMemo(() => {
    return (schemaStep1.isValidSync(formFields) && Boolean(documentInfo))
  }, [formFields, schema])

  const isValidSecondStep = useMemo(() => {
    return formState.isValid
  }, [formState])

  const onDocumentChange = useCallback(async (event) => {
    const file = event.target.files[0];
    console.log(file)
    const readFile = async () => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    const base64 = await readFile();
    setDocumentData(base64);
    setDocumentInfo({
      name: file.name,
      size: file.size,
      type: file.type,
    });
  }, [])

  return (
      <Grid item xs={12} sm={7}>
        <Paper classes={{
          root: classes.rootPaper
        }}>
          <Grid item xs={12}>
            <CustomSnackbar open={openErrorSnackbar} handleClose={handleClose} severity={'error'} message={`Errore nella richiesta: ${message}`}/>
            <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
              <Step1 setStep={setStep} step={step} register={register} errors={errors} isValid={isValidFirstStep} control={control} onDocumentChange={onDocumentChange} />
              <Step2 step={step} register={register} errors={errors} isValid={isValidSecondStep} isLoading={isLoading} />
              <Step3 step={step} />
            </form>
          </Grid>
        </Paper>
      </Grid>
  )
}
