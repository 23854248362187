import React from 'react'
import styles from './index.module.css'

import {
  Grid,
  Typography,
  Link
} from '@material-ui/core'
import cn from 'classname'

export default ({ step }) => {
  return (
    <Grid container spacing={2} justify={'center'} className={cn(styles.container, (step !== 2) ? styles.doNotDisplay : null) }>
       <Typography className={styles.signinTypography}>
         Sei Iscritto!
       </Typography>
       <Typography className={styles.yourHelpTypography}>
         Il tuo contributo è importante
       </Typography>
       <Typography variant={'body1'}>
         Per tornare alla Home <Link className={styles.goBackLink} href={'/'}>clicca qui </Link>
       </Typography>
    </Grid>
  )
}
